var
    cookieNotice = document.getElementById('cookie-notice'),
    cookieAccept = document.getElementById('cookie-accept'),
    cookieValue = 'on';

function createCookie(name,value) {
    document.cookie = name+"="+value+"; path=/";
    cookieNotice.classList.remove('hidden');
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function removeNotice() {
    cookieNotice.parentNode.removeChild(cookieNotice);
}

var cookie_onload = function() {
    var cookieName = 'act-cookie-' + cookieNotice.dataset.cookieId;

    if(checkCookie(cookieName) != cookieValue) {
        createCookie(cookieName, cookieValue);
    } else {
        removeNotice();
    }
};

var accept_onclick = function(e) {
    e.preventDefault();

    removeNotice();
};

if (cookieNotice !== null) {
    window.onload = cookie_onload;
    cookieAccept.onclick = accept_onclick;
}
