/* global $, FlipClock */

if($('.countdown-timer').length) {
    $('.countdown-timer').each(function() {
        "use strict";
        var today = Date.parse(new Date());
        var event = Date.parse($(this).data('event'));
        var countdown_val = (event - today) / 1000;
        var hundred_days = 8640000; /* seconds */
        if(countdown_val >= hundred_days) { $(this).addClass('clock-lg'); }
        /* if more than 3 seconds remaining create timer */
        if(countdown_val >= 3) {
            var clock = new FlipClock($(this), countdown_val, {
                clockFace: 'DailyCounter',
                countdown: true
            });
        }
    });
}
